// Group hover visibility toggle 

.group-item-invisible {
  .group-item {
    visibility: hidden;
  }

}

.group-item-visible-hover {
  &:hover {
    .group-item {
      visibility: visible;
    }
  }
}
